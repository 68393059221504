import { axios } from '@/utils/request'


export function getUsers () {
  return axios({
    url: '/api/users',
    method: 'get'
  })
}

export function queryUser (data, query) {
  return axios({
    url: '/api/users/queryUser',
    method: 'post',
    data: data,
    params: query
  })
}

export function getUserDetial (username) {
  return axios({
    url: '/api/users/'+username+'?formFields=true',
    method: 'get'
  })
}

export function putUserDetial (user) {
  return axios({
    url: '/api/users/'+user.username,
    method: 'put',
    data: user
  })
}

export function saveUser (user) {
  return axios({
    url: '/api/users',
    method: 'put',
    data: user
  })
}

export function getUsersCreateFields () {
  return axios({
    url: '/api/formData/userFields',
    method: 'get'
  })
}
export function delUser (username) {
  return axios({
    url: '/api/users/'+username,
    method: 'delete'
  })
}

export function getUserToken (username,expireSeconds) {
  return axios({
    url: '/api/users/'+username+'/generate-security-token',
    method: 'get',
    query: {
      'expireSeconds': expireSeconds
    }
   })
}

export function generateUserSecurityToken (data) {
  return axios({
    url: '/api/users/generate-current-security-token',
    method: 'get',
    params: data
  })
}




